import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useState } from 'react';
import { LogoWithDisc } from '../LogoWithDisc';
import { cn } from '../utils';
export const SplitLogoGrid = ({ logos }) => {
    const [showAll, setShowAll] = useState(false);
    const initialLogos = logos.slice(0, 5);
    const remainingLogos = logos.slice(5);
    return (_jsxs("div", { "data-component": "SplitLogoGrid", className: cn('w-full border-b border-divider-surface', remainingLogos.length ? 'pb-0' : 'pb-4'), children: [_jsx("div", { className: "flex flex-wrap justify-center items-center gap-2 px-4 py-0 w-full", children: initialLogos.map((logo) => (_createElement(LogoWithDisc, Object.assign({}, logo, { altBg: false, direction: "col", key: logo.alt })))) }), remainingLogos.length > 0 && (_jsxs(_Fragment, { children: [_jsx("div", { className: cn('transition-all duration-300 ease-in-out', showAll ? 'opacity-100 max-h-96' : 'opacity-0 max-h-0 overflow-hidden'), children: _jsx("div", { className: "flex flex-wrap justify-center items-center gap-2 px-4 py-0 mt-4 w-full", children: remainingLogos.map((logo) => (_createElement(LogoWithDisc, Object.assign({}, logo, { altBg: false, direction: "col", key: logo.alt })))) }) }), _jsx("div", { className: "flex justify-center w-full", children: _jsxs("button", { onClick: () => setShowAll(!showAll), className: "px-4 py-4 text-on-background-alt inline-flex items-center gap-2 group metadata-xs", children: [showAll ? 'View Less' : 'View More', _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 24 24", fill: "none", className: `
                  transition-transform duration-300 ease-in-out
                  ${showAll ? 'rotate-0' : 'rotate-180'}
                `, children: _jsx("path", { d: "M11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L18.7071 13.2929C19.0976 13.6834 19.0976 14.3166 18.7071 14.7071C18.3166 15.0976 17.6834 15.0976 17.2929 14.7071L12 9.41421L6.70711 14.7071C6.31658 15.0976 5.68342 15.0976 5.29289 14.7071C4.90237 14.3166 4.90237 13.6834 5.29289 13.2929L11.2929 7.29289Z", fill: "currentColor" }) })] }) })] }))] }));
};
