var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { logoLeagueToProps } from '../LogoWithDisc';
// TODO: Move string utils package.  Currently they are wrapped up in shared-web
export const ISO8601ToMonthDateFullYear = (iso8601) => {
    const date = new Date(iso8601);
    return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
};
export const USDCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
const BILLING_CONFIG = {
    annual: { duration: '/yr', description: '' },
    monthly: { duration: '/mo', description: 'Pay Monthly' },
    seasonal: { duration: '/season', description: 'ACCESS THROUGH SEPT 29' },
};
const mapBillingOption = (option, type, disablePromos) => {
    const { service_id, price, promotions } = option;
    let promotionText;
    let promotionColor;
    let promoPrice;
    if (promotions && !disablePromos) {
        const freeTrial = promotions.find((promo) => promo.is_default_promotion && promo.is_free_trial);
        const promo = promotions.find((promo) => promo.is_default_promotion && !promo.is_free_trial);
        if (freeTrial) {
            promotionText = freeTrial.description;
            promotionColor = 'gray';
        }
        else if (promo) {
            promotionText = promo.description;
            promotionColor = 'green';
            promoPrice = promo.promotion_price;
        }
    }
    return Object.assign(Object.assign(Object.assign({ id: service_id, price: `$${price.toFixed(2)}`, promoPrice: promoPrice ? `$${promoPrice.toFixed(2)}` : undefined }, BILLING_CONFIG[type]), (promotionText && { promotionText })), (promotionColor && { promotionColor }));
};
const mapPurchaseOptionsToBillingOptions = (purchaseOptions, options = {} // Use options object
) => {
    const { disablePromos = false } = options; // Destructure options
    const billingOptions = [];
    // Order: monthly, seasonal, annual
    if (purchaseOptions.monthly) {
        billingOptions.push(mapBillingOption(purchaseOptions.monthly, 'monthly', disablePromos));
    }
    if (purchaseOptions.seasonal) {
        billingOptions.push(mapBillingOption(purchaseOptions.seasonal, 'seasonal', disablePromos));
    }
    if (purchaseOptions.annual) {
        billingOptions.push(mapBillingOption(purchaseOptions.annual, 'annual', disablePromos));
    }
    return billingOptions;
};
const getPurchaseDescription = (pkg, billingOptions) => {
    const intervalMap = {
        monthly: 'monthly',
        annual: 'yearly',
        manual: 'season',
    };
    const billingTypeMap = {
        monthly: (options) => options.find((opt) => opt.duration === '/mo'),
        annual: (options) => options.find((opt) => opt.duration === '/yr'),
        manual: (options) => options.find((opt) => opt.duration === '/season'),
    };
    const interval = pkg.billing_interval;
    if (!interval || !billingTypeMap[interval])
        return undefined;
    const option = billingTypeMap[interval](billingOptions);
    if (!option)
        return undefined;
    return `Your ${intervalMap[interval]} subscription: ${option.price}`;
};
const mapPackageToCardProps = (pkg, options = {} // Use options object
) => {
    var _a;
    const { disablePromos = false } = options; // Destructure options
    const billingOptions = mapPurchaseOptionsToBillingOptions(pkg.purchase_options, { disablePromos });
    const baseProps = {
        editorial_header: pkg.editorial_header,
        bundle_header: pkg.bundle_header,
        package_name: pkg.package_name,
        description: pkg.description,
        logos: pkg.logos.map((logo) => (Object.assign({}, logoLeagueToProps(logo)))),
        billingOptions,
        disclaimer: pkg.disclaimer,
        purchase_success_msg: pkg.purchase_success_msg,
    };
    // Type guard to check if this is a PurchasedPackageObjV2
    if ('management_url' in pkg) {
        const nextBillingDescription = pkg.dtc_product_type === 'package-seasonal'
            ? 'Start of Fall 2024 season (TBD)'
            : ISO8601ToMonthDateFullYear((_a = pkg.next_bill_date) !== null && _a !== void 0 ? _a : '');
        return Object.assign(Object.assign({}, baseProps), { purchased_description: getPurchaseDescription(pkg, billingOptions), purchased_description_2: `Next billing date: ${nextBillingDescription}`, management_url: pkg.management_url });
    }
    // If it's not a PurchasedPackageObjV2, return without management_url and purchased descriptions
    return Object.assign(Object.assign({}, baseProps), { purchased_description: undefined, purchased_description_2: undefined });
};
const areArraysEqual = (arr1, arr2) => JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
const mergeSeasonalOptions = (packages) => {
    const updatedPackages = packages.available_packages.map((pkg) => {
        var _a;
        const matchingSeasonalPkg = (_a = packages.available_seasonal_packages) === null || _a === void 0 ? void 0 : _a.find((seasonalPkg) => areArraysEqual(seasonalPkg.dtc_product_ids, pkg.dtc_product_ids));
        if (matchingSeasonalPkg && matchingSeasonalPkg.purchase_options.seasonal) {
            return Object.assign(Object.assign({}, pkg), { purchase_options: Object.assign(Object.assign({}, pkg.purchase_options), { seasonal: matchingSeasonalPkg.purchase_options.seasonal }) });
        }
        return pkg;
    });
    return Object.assign(Object.assign({}, packages), { available_packages: updatedPackages, available_seasonal_packages: [] });
};
export const mapPackagesData = (data, options = {} // Use options object
) => {
    const { disablePromos = false } = options; // Destructure options
    data = mergeSeasonalOptions(data);
    const bundles = data.available_bundles.map((pkg) => mapPackageToCardProps(pkg, { disablePromos }));
    const regularPackages = [...data.available_packages, ...data.available_seasonal_packages].map((pkg) => mapPackageToCardProps(pkg, { disablePromos }));
    const purchasedPackages = data.purchased_packages.map((pkg) => mapPackageToCardProps(pkg, { disablePromos }));
    // Create arrays of raw package data
    const rawPackageGroups = [
        ...(data.available_bundles.length ? [data.available_bundles] : []),
        ...([...data.available_packages, ...data.available_seasonal_packages].length
            ? [[...data.available_packages, ...data.available_seasonal_packages]]
            : []),
    ];
    return {
        packageGroups: [
            ...(bundles.length
                ? [
                    {
                        label: 'Bundle & Save',
                        packages: bundles.map((_a) => {
                            var { onBillingOptionSelect, billingOptionSelectedId } = _a, rest = __rest(_a, ["onBillingOptionSelect", "billingOptionSelectedId"]);
                            return rest;
                        }),
                    },
                ]
                : []),
            ...(regularPackages.length
                ? [
                    {
                        label: 'Base Packages',
                        packages: regularPackages.map((_a) => {
                            var { onBillingOptionSelect, billingOptionSelectedId } = _a, rest = __rest(_a, ["onBillingOptionSelect", "billingOptionSelectedId"]);
                            return rest;
                        }),
                    },
                ]
                : []),
        ],
        purchasedPackages: purchasedPackages.map((_a) => {
            var { onBillingOptionSelect, billingOptionSelectedId } = _a, rest = __rest(_a, ["onBillingOptionSelect", "billingOptionSelectedId"]);
            return rest;
        }),
        rawPackageGroups,
    };
};
export const mapSinglePackage = mapPackageToCardProps;
