import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SegmentedControl } from '../SegmentedControl';
import { Button } from '../button';
import { cn } from '../utils';
import { PackagesCard } from './PackagesCard';
function ResponsiveGrid({ children, itemCount, className }) {
    return (_jsx("div", { "data-component": "ResponsiveGrid", "data-theme": "light", className: cn('grid gap-4', {
            'grid-cols-1': true,
            'md:grid-cols-2': itemCount >= 2,
            'mdlg:grid-cols-3': itemCount >= 3,
        }, className), children: children }));
}
export function PackageSelector({ packageGroups, purchasedPackages, onBillingOptionSelect, billingOptionSelectedId = null, defaultActivePackageGroup = 0, onPackageGroupSelect, onWatchNow, }) {
    const segments = packageGroups.map((group) => ({
        label: group.label,
        content: (_jsx(ResponsiveGrid, { itemCount: group.packages.length, children: group.packages.map((pkg) => (_jsx(PackagesCard, Object.assign({}, pkg, { billingOptionSelectedId: billingOptionSelectedId, onBillingOptionSelect: onBillingOptionSelect }), pkg.package_name))) })),
    }));
    return (_jsxs("div", { "data-component": "PackageSelector", "data-theme": "dark", children: [segments.length > 0 && (_jsx(SegmentedControl, { segments: segments, defaultActiveIndex: defaultActivePackageGroup, onActiveIndexChange: onPackageGroupSelect })), !!(purchasedPackages === null || purchasedPackages === void 0 ? void 0 : purchasedPackages.length) && (_jsxs("div", { className: "flex flex-col items-center", children: [_jsx("p", { className: "heading-3 my-3", children: "Your Packages" }), packageGroups.length ? (_jsx("p", { className: "mb-3", children: "Review your current subscriptions below!" })) : (_jsxs(_Fragment, { children: [_jsx("p", { children: "You've already subscribed to all the packages available in your area." }), _jsx(Button, { onClick: onWatchNow, className: "w-[344px] my-6", size: "lg", children: "Watch Now" })] })), _jsx(ResponsiveGrid, { itemCount: purchasedPackages.length, children: purchasedPackages.map((pkg) => (_jsx("div", { "data-theme": "light", children: _jsx(PackagesCard, Object.assign({}, pkg)) }, pkg.package_name))) })] }))] }));
}
