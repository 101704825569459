var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { cn } from './utils';
// Style configurations
const sizeClasses = {
    sm: ['button-min', 'p-2'],
    md: ['button-sm', 'p-2'],
    lg: ['button-md', 'p-4'],
};
const variantClasses = {
    primary: ['text-canvas bg-on-background hover:bg-on-background/90 active:bg-button-pressed'],
    alt: [
        'bg-background hover:bg-on-background/20 active:bg-on-background/10 border border-on-background/20 active:border-on-background/10',
    ],
    live: ['text-white bg-live hover:bg-live-hover active:bg-live-pressed'],
};
// Common disabled styles
const disabledClasses = {
    primary: 'disabled:text-on-background/40 disabled:bg-disabled disabled:cursor-not-allowed',
    alt: 'disabled:text-on-background/40 disabled:bg-on-background/10 disabled:border disabled:border-on-background/20 disabled:cursor-not-allowed',
    live: 'disabled:text-on-background/40 disabled:bg-disabled disabled:cursor-not-allowed',
};
const Button = React.forwardRef((_a, ref) => {
    var { size = 'md', variant = 'primary', className } = _a, props = __rest(_a, ["size", "variant", "className"]);
    const buttonClasses = cn('rounded text-center', sizeClasses[size], variantClasses[variant], disabledClasses[variant], className);
    return _jsx("button", Object.assign({ "data-component": "Button", ref: ref, className: buttonClasses }, props));
});
Button.displayName = 'Button';
const LinkButton = React.forwardRef((_a, ref) => {
    var { size = 'md', variant = 'primary', className } = _a, props = __rest(_a, ["size", "variant", "className"]);
    const buttonClasses = cn('rounded text-center', sizeClasses[size], variantClasses[variant], disabledClasses[variant], className);
    return _jsx("a", Object.assign({ "data-component": "LinkButton", ref: ref, className: buttonClasses }, props));
});
LinkButton.displayName = 'LinkButton';
export { Button, LinkButton };
