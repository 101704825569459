import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const ColorSwatch = ({ color }) => {
    const rgbValue = getComputedStyle(document.documentElement).getPropertyValue(`--${color}-rgb`);
    const [r, g, b] = rgbValue.split(' ').map(Number);
    const hexValue = '#' +
        [r, g, b]
            .map((x) => x.toString(16).padStart(2, '0'))
            .join('')
            .toUpperCase();
    return (_jsxs("div", { className: "flex items-center gap-4 p-4 rounded bg-surface", children: [_jsx("div", { className: "", children: _jsx("div", { className: `w-20 h-20 rounded border border-on-background-alt bg-${color}` }) }), _jsxs("div", { children: [_jsxs("p", { className: "font-medium", children: ["bg-", color] }), _jsxs("p", { className: "text-sm text-on-background-alt", children: ["RGB: ", rgbValue] }), _jsxs("p", { className: "text-sm text-on-background-alt", children: ["HEX: ", hexValue] })] })] }));
};
export const ColorsDemo = () => {
    const themeColors = [
        'canvas',
        'surface',
        'on-background',
        'on-background-alt',
        'on-dark',
        'selection',
        'on-selection',
        'button',
        'button-pressed',
        'on-button',
        'on-button-alt',
        'divider-canvas',
        'divider-surface',
        'disabled',
    ];
    const commonColors = ['tint', 'error', 'success', 'live', 'live-hover', 'live-pressed', 'ad'];
    return (_jsxs("div", { className: "p-8 max-w-2xl", children: [_jsx("div", { className: "hidden bg-tint bg-error bg-success bg-live bg-live-hover bg-live-pressed bg-ad bg-canvas bg-surface bg-on-background bg-on-background-alt bg-on-dark bg-selection bg-on-selection bg-button bg-button-pressed bg-on-button bg-on-button-alt bg-divider-canvas bg-divider-surface bg-disabled" }), _jsx("h1", { className: "text-2xl font-bold mb-8", children: "Color System" }), _jsxs("div", { className: "space-y-8", children: [_jsxs("div", { className: "space-y-4", children: [_jsx("h2", { className: "text-xl font-semibold", children: "Theme Colors" }), themeColors.map((color) => (_jsx(ColorSwatch, { color: color }, color)))] }), _jsxs("div", { className: "space-y-4", children: [_jsx("h2", { className: "text-xl font-semibold", children: "Common Colors" }), commonColors.map((color) => (_jsx(ColorSwatch, { color: color }, color)))] })] })] }));
};
