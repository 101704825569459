import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { cn } from './utils';
export function SegmentedControl({ segments = [], activeIndex: controlledIndex, defaultActiveIndex = 0, onActiveIndexChange, }) {
    var _a;
    const [internalActiveIndex, setInternalActiveIndex] = useState(defaultActiveIndex);
    // Use controlled value if provided, otherwise use internal state
    const activeSegment = controlledIndex !== null && controlledIndex !== void 0 ? controlledIndex : internalActiveIndex;
    const handleSegmentClick = (index) => {
        onActiveIndexChange === null || onActiveIndexChange === void 0 ? void 0 : onActiveIndexChange(index);
        if (controlledIndex === undefined) {
            setInternalActiveIndex(index);
        }
    };
    return (_jsxs("div", { "data-component": "SegmentedControl", className: "w-full p-4", children: [_jsx("div", { className: "flex justify-center", children: _jsx("div", { className: "w-max grid auto-cols-fr grid-flow-col", children: segments.map((segment, index) => (_jsx("button", { onClick: () => handleSegmentClick(index), className: cn('px-9 py-3 border-x-0 border-y border-on-background-alt border-collapse focus-visible:outline-none focus-visible:ring focus-visible:ring-offset-2 focus-visible:ring-black label-primary-xs', index === 0 && 'border-l rounded-l', index === segments.length - 1 && 'border-r rounded-r', activeSegment !== index && activeSegment !== index + 1 && 'border-r', activeSegment === index ? 'bg-on-background text-canvas' : 'text-on-background'), children: segment.label }, index))) }) }), _jsx("div", { className: "mt-4 flex justify-center", children: (_a = segments[activeSegment]) === null || _a === void 0 ? void 0 : _a.content })] }));
}
