import { GrandstandInputProps } from '@/components/Input'
import { MetaDataLabel } from '@/styles/fonts'
import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import { UserService, UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { KeyboardEventHandler, RefObject, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

interface GrandstandZipcodeInputProps extends Omit<GrandstandInputProps, 'onChange'> {
  onChange: (zipcode: string) => void
  locale?: string
  errorMsg?: string
  inputRef?: ((instance: HTMLInputElement | null) => void) | RefObject<HTMLInputElement> | null | undefined
  disabled?: boolean
}

const Entry = styled.div<{ error?: boolean; disabled?: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border: ${(props) => (props.error ? '1px solid var(--error)' : '1px solid var(--divider-canvas)')};
  border-radius: 4px;
  color: ${(props) => (props.disabled ? 'var(--on-background-alt)' : 'var(--on-background)')};
`
const EntryGroup = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
`
const EntryInput = styled.input`
  border: none;
  background: none;
  font-size: var(--metadata-md-size);
  &:focus {
    outline: none;
  }
`

const ZipLabel = styled(MetaDataLabel)`
  color: var(--on-background-alt);
  text-transform: capitalize;
`

const EntryLabel = styled(MetaDataLabel)<{ localeValue: string }>`
  color: var(--on-background-alt);
  text-transform: capitalize;
  ${({ localeValue }) => (localeValue !== 'undefined, undefined' ? '' : 'min-height: 28px;')}
`
const LocaleGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const LocaleText = styled(MetaDataLabel)``
const IconWrapper = styled.div`
  margin-left: 8px;
`
const ErrorMsg = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 16px;
  gap: 16px;
  width: 480px;
  height: 22px;
  text-align: left;
`
const ErrorMsgText = styled(MetaDataLabel)`
  color: var(--error);
`

const getLocale = (userService: UserService): string => {
  const city = userService.currentUser?.profile.region.city
  const state = userService.currentUser?.profile.region.state
  return [city, state].filter(Boolean).join(', ')
}
const GrandstandZipcodeInput = ({
  onChange,
  errorMsg,
  placeholder,
  inputRef,
  value,
  locale,
  disabled = false,
}: GrandstandZipcodeInputProps) => {
  const [inputValue, setInputValue] = useState<string>((value as string) ?? '')
  const [error, setError] = useState(false)
  const userService = useContext(UserServiceContext)
  const [_locale, setLocale] = useState(getLocale(userService))

  const validateZipcode = (zipcode: string) => {
    const zipcodeRegex = /(^\d{5}$)/
    return zipcodeRegex.test(zipcode)
  }

  const handleZipcodeChange = (event: { target: { value: string } }) => {
    const newZipcode = event.target.value
    setInputValue(newZipcode)
    const isValidZipcode = validateZipcode(newZipcode)
    setError(!isValidZipcode)
    if (isValidZipcode) {
      onChange(newZipcode)
    }
  }

  const handleZipcodeKeyEvent: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const keyCode = event?.keyCode ?? event?.which ?? -1
    const code = event?.code ?? 'Unknown'
    const key = event?.key ?? 'Unknown'
    if (keyCode === 8 || [key, code].includes('Backspace')) {
      event.stopPropagation()
    }
  }

  useEffect(() => {
    if (locale) {
      setLocale(locale)
    } else {
      setLocale(getLocale(userService))
    }
  }, [
    inputValue,
    locale,
    userService,
    userService.currentUser?.profile.region.state,
    userService.currentUser?.profile.region.city,
  ])

  return (
    <Entry error={error ? true : false} disabled={disabled}>
      <EntryGroup>
        <ZipLabel fontSize="min">Zip Code</ZipLabel>
        <EntryInput
          value={inputValue}
          ref={inputRef}
          placeholder={placeholder}
          minLength={5}
          maxLength={5}
          onChange={handleZipcodeChange}
          disabled={disabled}
          type="text"
          inputMode="numeric"
          onKeyDown={handleZipcodeKeyEvent}
          onKeyUp={handleZipcodeKeyEvent}
        />
      </EntryGroup>
      <LocaleGroup>
        <EntryLabel fontSize="min" localeValue={_locale}>
          City, State
        </EntryLabel>
        <LocaleText fontSize="md">{_locale !== 'undefined, undefined' ? _locale : ' '}</LocaleText>
      </LocaleGroup>
      {disabled && (
        <IconWrapper>
          <GrandstandIcon icon="lock_lock" state="disabled" />
        </IconWrapper>
      )}
    </Entry>
  )
}

export default GrandstandZipcodeInput
