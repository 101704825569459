import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Image from 'next/image';
import { cn } from './utils';
export const partnerTeamToProps = ({ team, hideTvProviderDisclaimer = false, }) => {
    return {
        alt: team.name || '',
        title: team.name,
        subtitle: `${team.league}${hideTvProviderDisclaimer || team.dtc ? '' : ' • TV Provider Only'}`,
        lightSrc: team.light_image || '',
        darkSrc: team.dark_image || '',
    };
};
export const cmsRegionTeamToProps = ({ team, hideTvProviderDisclaimer = false, }) => {
    return {
        alt: team.short_name || '',
        title: team.short_name,
        subtitle: `${team.league}${hideTvProviderDisclaimer || team.DTCavailable ? '' : ' • TV Provider Only'}`,
        lightSrc: team.light_image || '',
        darkSrc: team.dark_image || '',
    };
};
export const logoLeagueToProps = ({ team = 'logo', light_logo = '', dark_logo = '', name = '', }) => {
    return { alt: team, lightSrc: light_logo, darkSrc: dark_logo, title: name };
};
const discSizeClasses = {
    default: ['p-[15px]'],
    cw: ['p-[24px]'],
};
const logoSizes = {
    default: 26,
    cw: 40,
};
// TODO: Automatically add data-attribute and name based on component name
export const LogoWithDisc = ({ alt, lightSrc, darkSrc, altBg = true, direction = 'row', title, subtitle, className, size = 'default', }) => {
    const logoSize = logoSizes[size];
    return (_jsxs("div", { "data-component": "LogoWithDisc", className: cn('max-content inline-flex items-center', direction === 'row' ? 'gap-2' : 'gap-0', {
            'flex-col': direction === 'col',
        }), children: [_jsxs("div", { className: cn(discSizeClasses[size], `w-fit size-auto rounded-full `, { 'bg-surface': altBg }, { 'bg-canvas': !altBg }, className), children: [_jsx(Image, { alt: alt, src: lightSrc, width: logoSize, height: logoSize, className: "display-theme-light" }), _jsx(Image, { alt: alt, src: darkSrc, width: logoSize, height: logoSize, className: "display-theme-dark" })] }), _jsxs("div", { className: cn('flex flex-col justify-center', { 'items-center': direction === 'col' }), children: [title ? _jsx("p", { className: "text-on-background", children: title }) : null, subtitle ? _jsx("p", { className: "text-on-background-alt text-sm", children: subtitle }) : null] })] }));
};
