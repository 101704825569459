import CaptivateAnalytics from '@/components/CaptivateAnalytics'
import { TopNavProvider, useTopNavStore } from '@/components/TopNav/TopNavContext'
import { TopNavPortals } from '@/components/TopNav/TopNavPortals'
import { ModalProvider } from '@/contexts/ModalContext'
import ToastContextProvider from '@/contexts/ToastContext'
import useMediaQuery from '@/hooks/useMediaQuery'
import '@/styles/globals.css'
import '@/styles/tealium-overrides.css'
import { datadogLogs } from '@datadog/browser-logs'
import DDRum from '@grandstand-web/bally-web-shared/src/components/DDRum'
import { clientRuntimeConfig } from '@grandstand-web/bally-web-shared/src/config'
import { useLocalStorage } from '@grandstand-web/bally-web-shared/src/hooks/useLocalStorage'
import { useSessionStorage } from '@grandstand-web/bally-web-shared/src/hooks/useSessionStorage'
import { initializeStringMgmt } from '@grandstand-web/bally-web-shared/src/newPackages/StringMgmt'
import TosRedirect from '@grandstand-web/bally-web-shared/src/newPackages/TermsOfService/components/TosRedirect'
import { ServiceProvider } from '@grandstand-web/bally-web-shared/src/services/ServiceProvider'
import { GlobalStateContextWrapper } from '@grandstand-web/bally-web-shared/src/services/config/GlobalStateService'
import type { SelectedPkg } from '@grandstand-web/bally-web-shared/src/type/SelectedPkg'
import { getCodeEnvironment, isNotInProd } from '@grandstand-web/bally-web-shared/src/utils/envUtils'
// TODO: Understand why I need to reference dist here?
import { usePartnerSource } from '@/hooks/usePartnerSource'
import { PackageDataResult } from '@grandstand-web/bally-web-shared/src/utils/presentationModels/PackageObjectV2'
import { PlayerSharedProvider } from '@grandstand-web/player/src/context/PlayerSharedContext'
import '@grandstand-web/ui/dist/tailwind.css'
import { ActiveSubscriptionsResponse } from '@grandstand/presentation-models'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'
import Footer from '../components/Footer'
import GlobalStyles from '../styles/components/GlobalStyles'
const Modal = require('react-modal')
if (clientRuntimeConfig.dataDogClientToken) {
  try {
    datadogLogs.init({
      clientToken: clientRuntimeConfig.dataDogClientToken,
      site: 'datadoghq.com',
      service: 'web',
      env: getCodeEnvironment(),
      version: String(clientRuntimeConfig.rootVersion),
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    })
  } catch (err) {
    console.log(err)
  }
}

initializeStringMgmt('web')

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
`
export type { SelectedPkg }
export type PkgSelectCtx = {
  selectedPkg: SelectedPkg | undefined
  setSelectedPkg: Dispatch<SetStateAction<SelectedPkg | undefined>> | undefined
  userIsSubscribedToSelectedPackage:
    | ((selectedPkg: SelectedPkg | undefined, allSubs: ActiveSubscriptionsResponse) => boolean)
    | undefined
  setSelectedPackageFromPackageData?: (
    packageData: PackageDataResult,
    selectedZip: string,
    packagesLocale: string
  ) => void
  marketingChannelPromo: string | undefined
  setMarketingChannelPromo: Dispatch<SetStateAction<string | undefined>> | undefined
}

export const PkgSelectionContext = createContext<PkgSelectCtx>({
  selectedPkg: undefined,
  setSelectedPkg: undefined,
  userIsSubscribedToSelectedPackage: undefined,
  setSelectedPackageFromPackageData: undefined,
  marketingChannelPromo: undefined,
  setMarketingChannelPromo: undefined,
})

export type MultiStageAuthCtx = {
  verifiedCode?: string
  partnerSource?: string
  setVerifiedCode?: Dispatch<SetStateAction<string | undefined>>
  setPartnerSource?: Dispatch<SetStateAction<string | undefined>>
}

export const MultiStageAuthContext = createContext<MultiStageAuthCtx>({})

// For modal system: required by `react-modal`
Modal.setAppElement('#__next')

const ContentWrapper = styled.div<{ safeAreaTop: number }>`
  flex-grow: 1;
  padding-top: ${({ safeAreaTop }) => safeAreaTop}px;
`

const WithProviders = ({ children, applyPadding }: PropsWithChildren<{ applyPadding: boolean }>) => {
  const { safeAreaTop } = useTopNavStore()
  return <ContentWrapper safeAreaTop={applyPadding ? safeAreaTop : 0}>{children}</ContentWrapper>
}

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const isSettingsPage = router.pathname.startsWith('/settings')
  const applyPadding = !isSettingsPage
  const darkModeSelected = useMediaQuery('(prefers-color-scheme: dark)')
  const [selectedPkg, setSelectedPkg] = useLocalStorage<SelectedPkg | undefined>('selected-pkg', undefined)
  const [verifiedCode, setVerifiedCode] = useState<string>()
  const [partnerSource, setPartnerSource] = usePartnerSource()
  const isEmbedded = router.pathname.includes('_embed')
  const [marketingChannelPromo, setMarketingChannelPromo] = useSessionStorage<string | undefined>(
    'marketing_channel_promo',
    undefined
  )

  const setSelectedPackageFromPackageData = (
    packageData: PackageDataResult,
    selectedZip: string,
    packagesLocale: string
  ) => {
    if (packageData) {
      const { package: pkg, variant_type, purchase_option } = packageData
      setSelectedPkg({
        ...pkg,
        ...purchase_option,
        service_id: purchase_option.service_id,
        variant_name: purchase_option.variant_name,
        price: purchase_option.price,
        bill_cycle: variant_type === 'seasonal' ? 'manual' : variant_type,
        app_channel_ids: purchase_option.app_channel_ids,
        package_zipcode: selectedZip,
        package_locale: packagesLocale,
      })
    }
  }

  useEffect(() => {
    if (darkModeSelected) {
      document.documentElement.setAttribute('data-theme', 'dark')
    } else {
      document.documentElement.setAttribute('data-theme', 'light')
    }
  }, [darkModeSelected])

  // Selecting a package removes the "Connect to TV Provider" option from signin views.
  // Since this value is stored indefinitely in local storage, users can be permanently stuck in this state.
  // The purpose of this effect is to clear the selected package when a user "exits the purchase flow".
  // See: WW-1376
  useEffect(() => {
    if (!/^\/(packages|signin|checkout)/.test(router.asPath)) {
      setSelectedPkg(undefined)
    }
  }, [setSelectedPkg, router.asPath])

  useEffect(() => {
    const promo = router.query.marketing_channel_promo
    if (promo) {
      setMarketingChannelPromo(promo as string)
    }
  }, [router.query, setMarketingChannelPromo])

  const userIsSubscribedToSelectedPackage = (
    selectedPkg: SelectedPkg | undefined,
    allSubs: ActiveSubscriptionsResponse
  ) => {
    if (!selectedPkg || !allSubs || allSubs.length <= 0) {
      return false
    }
    return allSubs
      .filter((sub) => sub.subscription_status !== 'expired')
      .map((sub) => sub.service_id)
      .includes(selectedPkg.service_id)
  }

  return (
    <>
      <Head>
        <title>FanDuel Sports Network</title>
        {(isNotInProd() || clientRuntimeConfig.isEphemeral) && <meta name="robots" content="noindex,nofollow" />}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="favicon.png" />
      </Head>
      <ServiceProvider gtmId={clientRuntimeConfig.gtmId} app="web">
        <DDRum service="web" />
        <CaptivateAnalytics />
        <TosRedirect />
        <GlobalStyles />
        <GlobalStateContextWrapper>
          <PkgSelectionContext.Provider
            value={{
              selectedPkg,
              setSelectedPkg,
              userIsSubscribedToSelectedPackage,
              setSelectedPackageFromPackageData,
              marketingChannelPromo,
              setMarketingChannelPromo,
            }}
          >
            <MultiStageAuthContext.Provider value={{ verifiedCode, partnerSource, setPartnerSource, setVerifiedCode }}>
              <TopNavProvider>
                <ToastContextProvider>
                  <ModalProvider>
                    <PlayerSharedProvider>
                      <PageWrapper>
                        {/* Don't show the Top Nav if embedded */ !isEmbedded && <TopNavPortals />}
                        <WithProviders applyPadding={applyPadding}>
                          <Component {...pageProps} />
                        </WithProviders>
                        {/* Don't show the footer if embedded */ !isEmbedded && <Footer />}
                      </PageWrapper>
                    </PlayerSharedProvider>
                  </ModalProvider>
                </ToastContextProvider>
              </TopNavProvider>
            </MultiStageAuthContext.Provider>
          </PkgSelectionContext.Provider>
        </GlobalStateContextWrapper>
      </ServiceProvider>
    </>
  )
}
